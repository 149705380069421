var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "sub-module-title", staticStyle: { padding: "0" } },
        [_vm._v("① Bunkerer Documents")]
      ),
      _c(
        "financer-file-upload",
        _vm._b(
          {
            attrs: {
              title: "Notice of Assignment",
              fieldName: "noticeOfAssignmentDocuments",
              status: _vm.$attrs.status,
              allowEdit: _vm.allowEdit,
              disabledUploadOrDeleteFiles: _vm.disabledUploadRequestFiles,
              isMultiple: false,
              isShowActionCheckBar: true,
              uploadFiles: _vm.nftFormData.noticeOfAssignmentDocuments,
              entityNftFormData: _vm.entityNftFormData,
            },
            on: {
              setUploadFiles: _vm.setUploadFiles,
              sendNoaReminder: _vm.sendNoaReminder,
              onUpdateAcknowledgeNoa: _vm.onUpdateAcknowledgeNoa,
            },
          },
          "financer-file-upload",
          _vm.$attrs,
          false
        )
      ),
      _c("financer-file-upload", {
        attrs: {
          title: "Other Documents",
          fieldName: "otherDocuments",
          status: _vm.$attrs.status,
          allowEdit: _vm.allowEdit,
          disabledUploadOrDeleteFiles: _vm.disabledUploadRequestFiles,
          isMultiple: true,
          uploadFiles: _vm.nftFormData.otherDocuments,
        },
        on: { setUploadFiles: _vm.setUploadFiles },
      }),
      _c("financer-file-upload", {
        attrs: {
          title: "Invoice",
          fieldName: "supportInvoiceDocuments",
          status: _vm.$attrs.status,
          allowEdit: _vm.allowEdit,
          disabledUploadOrDeleteFiles: _vm.disabledUploadRequestFiles,
          isMultiple: true,
          uploadFiles: _vm.nftFormData.supportInvoiceDocuments,
        },
        on: { setUploadFiles: _vm.setUploadFiles },
      }),
      _c(
        "div",
        {
          staticClass: "sub-module-title",
          staticStyle: { padding: "20px 0px 10px 0px" },
        },
        [_vm._v("② Financer Documents")]
      ),
      _c("financer-file-upload", {
        attrs: {
          title: "MT103",
          fieldName: "mtOneZeroThreeDocuments",
          status: _vm.$attrs.status,
          allowEdit:
            _vm.$attrs.isFinancier &&
            _vm.$attrs.view === _vm.FINANCE_VIEW.EDIT_FINANCE &&
            _vm.$attrs.status !== _vm.FINANCE_STATUS[4],
          disabledUploadOrDeleteFiles: !(
            _vm.$attrs.isFinancier &&
            _vm.$attrs.status === _vm.FINANCE_STATUS[2] &&
            _vm.$attrs.view === _vm.FINANCE_VIEW.EDIT_FINANCE
          ),
          uploadFiles: _vm.nftFormData.mtOneZeroThreeDocuments,
        },
        on: { setUploadFiles: _vm.setUploadFiles },
      }),
      _c(
        "div",
        {
          staticClass: "sub-module-title",
          staticStyle: { width: "100%", padding: "20px 0px 10px 0px" },
        },
        [_vm._v("③ Receivable Information ")]
      ),
      _c(
        "el-form",
        {
          ref: "recivedForm",
          attrs: { "label-position": "top", model: _vm.entityNftFormData },
        },
        [
          _c(
            "div",
            _vm._l(_vm.receiveInfoList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticStyle: {
                    display: "grid",
                    "justify-content": "space-between",
                    "grid-template-columns": "40% 40% 10%",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Received Amount",
                        prop: "amountReceived",
                        rules: _vm.rules.notReqRule,
                      },
                    },
                    [
                      _c("el-input-number", {
                        attrs: { controls: false, precision: 3, min: 0 },
                        on: {
                          change: (e) =>
                            _vm.setReceivableInfo(e, "amountReceived", index),
                        },
                        model: {
                          value: item.amountReceived,
                          callback: function ($$v) {
                            _vm.$set(item, "amountReceived", $$v)
                          },
                          expression: "item.amountReceived",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Received Date",
                        prop: "dateReceived",
                        rules: _vm.rules.notReqRule,
                      },
                    },
                    [
                      _c("el-date-picker", {
                        on: {
                          change: (e) =>
                            _vm.setReceivableInfo(e, "dateReceived", index),
                        },
                        model: {
                          value: item.dateReceived,
                          callback: function ($$v) {
                            _vm.$set(item, "dateReceived", $$v)
                          },
                          expression: "item.dateReceived",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { margin: "auto auto" } },
                    [
                      _c("el-button", {
                        attrs: {
                          type: "danger",
                          icon: "el-icon-delete",
                          circle: "",
                          size: "mini",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.removeRecevableInfoLine(index)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            0
          ),
          _c(
            "div",
            { staticStyle: { "padding-top": "20px" } },
            [
              _vm.$attrs.isFinancier &&
              [_vm.FINANCE_STATUS[2], _vm.FINANCE_STATUS[3]].includes(
                _vm.$attrs.status
              )
                ? _c("el-divider", { staticStyle: { "padding-top": "20px" } }, [
                    _c("i", {
                      staticClass: "el-icon-circle-plus",
                      staticStyle: { "font-size": "30px", color: "#0F376A" },
                      on: { click: _vm.addedRecevableInfoLine },
                    }),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }