<template>
    <div>
      <div class="sub-module-title" style="padding:0">① Bunkerer Documents</div>
      <financer-file-upload
        title="Notice of Assignment"
        fieldName="noticeOfAssignmentDocuments"
        :status="$attrs.status"
        :allowEdit="allowEdit"
        :disabledUploadOrDeleteFiles="disabledUploadRequestFiles"
        :isMultiple="false"
        :isShowActionCheckBar="true"
        :uploadFiles="nftFormData.noticeOfAssignmentDocuments"
        @setUploadFiles="setUploadFiles"
        @sendNoaReminder="sendNoaReminder"
        @onUpdateAcknowledgeNoa="onUpdateAcknowledgeNoa"
        :entityNftFormData="entityNftFormData"
        v-bind="$attrs"
      />
      <financer-file-upload
        title="Other Documents"
        fieldName="otherDocuments"
        :status="$attrs.status"
        :allowEdit="allowEdit"
        :disabledUploadOrDeleteFiles="disabledUploadRequestFiles"
        :isMultiple="true"
        :uploadFiles="nftFormData.otherDocuments"
        @setUploadFiles="setUploadFiles"
      />
      <financer-file-upload
        title="Invoice"
        fieldName="supportInvoiceDocuments"
        :status="$attrs.status"
        :allowEdit="allowEdit"
        :disabledUploadOrDeleteFiles="disabledUploadRequestFiles"
        :isMultiple="true"
        :uploadFiles="nftFormData.supportInvoiceDocuments"
        @setUploadFiles="setUploadFiles"
      />
      <div class="sub-module-title" style="padding:20px 0px 10px 0px">② Financer Documents</div>
      <financer-file-upload
        title="MT103"
        fieldName="mtOneZeroThreeDocuments"
        :status="$attrs.status"
        :allowEdit="$attrs.isFinancier && $attrs.view === FINANCE_VIEW.EDIT_FINANCE && $attrs.status !== FINANCE_STATUS[4]"
        :disabledUploadOrDeleteFiles="!($attrs.isFinancier && $attrs.status === FINANCE_STATUS[2] && $attrs.view === FINANCE_VIEW.EDIT_FINANCE)"
        :uploadFiles="nftFormData.mtOneZeroThreeDocuments"
        @setUploadFiles="setUploadFiles"
      />
       <!-- <div class="flex-between-row" style="width:100%; padding:20px 0px 10px 0px"> -->
      <!-- <div class="sub-module-title" style="padding:0">③ Receivable  Information </div> -->
      <div class="sub-module-title" style="width:100%; padding:20px 0px 10px 0px">③ Receivable  Information </div>
        <!-- <el-button v-if="$attrs.isFinancier" type="primary"  round size="mini" @click="addNewReceiveLine">Add</el-button> -->
      <el-form :label-position="'top'" :model="entityNftFormData" ref="recivedForm">
        <div >
          <div v-for="(item, index) in receiveInfoList" :key="index" style="display: grid;  justify-content: space-between;grid-template-columns:40% 40% 10%;">
          <el-form-item label="Received Amount" prop="amountReceived"  :rules="rules.notReqRule">
            <el-input-number
              v-model="item.amountReceived"
              :controls="false"
              :precision="3" :min="0"
              @change="(e)=>setReceivableInfo(e,'amountReceived',index)"
            >
            </el-input-number>
          </el-form-item>
          <el-form-item label="Received Date" prop="dateReceived"  :rules="rules.notReqRule">
            <el-date-picker v-model="item.dateReceived" @change="(e)=>setReceivableInfo(e,'dateReceived',index)" />
          </el-form-item>
         <div style="margin:auto auto">
            <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removeRecevableInfoLine(index)" >  </el-button>
          </div>
        </div>
        </div>

        <div style="padding-top:20px">
         <el-divider  v-if="$attrs.isFinancier && [FINANCE_STATUS[2],FINANCE_STATUS[3]].includes($attrs.status)" style="padding-top:20px"><i @click="addedRecevableInfoLine" class="el-icon-circle-plus" style="font-size: 30px; color:#0F376A" ></i></el-divider>
        </div>
      </el-form>
    </div>
</template>

<script>
import baseMixin from '@/mixins/base-mixin'
import baseTableMixin from '@/mixins/base-table-mixin'
import FinancerFileUpload from './FinancerFileUpload.vue'
import { HEADER_CELL_STYLE, CELL_STYLE, FINANCE_VIEW, FINANCE_STATUS } from '@/constants.js'
import { sendNoaAcknowledgementReminder, updateAcknowledgeNoa } from '@/services/modules/finance.js'
export default {
  name: 'OtherInfoForm',
  components: { FinancerFileUpload },
  mixins: [baseMixin, baseTableMixin],
  props: {
    nftFormData: Object,
    isReceivedInfoChanged: Boolean
  },
  data () {
    const rules = {
      reqRule: [{ required: true, message: 'This field is required', trigger: ['blur', 'change'] }],
      notReqRule: [{ required: false }]
    }
    const receiveInfoLine = {
      amountReceived: null,
      dateReceived: null
    }
    return {
      rules,
      receiveInfoLine,
      HEADER_CELL_STYLE,
      CELL_STYLE,
      FINANCE_STATUS,
      FINANCE_VIEW,
      entityNftFormData: {

      },
      receiveInfoList: []

    }
  },
  computed: {
    disabledUploadRequestFiles () {
      return this.$attrs.view !== this.FINANCE_VIEW.EDIT_FINANCE || this.$attrs.isFinancier || this.FINANCE_STATUS[1] !== this.$attrs.status
    },
    allowEdit () {
      return (this.$attrs.view === this.FINANCE_VIEW.EDIT_FINANCE || this.FINANCE_STATUS[4] === this.$attrs.status) && !this.$attrs.isFinancier
    }
  },
  created () {
    if (this.$attrs.formId) {
      this.entityNftFormData = this.nftFormData
      this.receiveInfoList = this.nftFormData.amountReceivables
    }
  },
  methods: {
    resetFields () {
      Object.assign(this.$data, this.$options.data())
    },
    setUploadFiles (fileList, fieldName) {
      this.entityNftFormData[fieldName] = fileList
    },
    updateAmountReceived () {
      this.$set(this.nftFormData, 'amountReceivables', this.receiveInfoList)
    },
    setReceivableInfo (data, fileName, index) {
      this.$emit('update:isReceivedInfoChanged', true)
      this.receiveInfoList[index][fileName] = data
      this.updateAmountReceived()
    },
    addedRecevableInfoLine () {
      this.receiveInfoList.push(this.$_.cloneDeep(this.receiveInfoLine))
    },
    removeRecevableInfoLine (index) {
      this.receiveInfoList.splice(index, 1)
      this.$emit('update:isReceivedInfoChanged', true)
      this.updateAmountReceived()
    },
    sendNoaReminder () {
      sendNoaAcknowledgementReminder(this.$attrs.formId).then(resp => {
        if (resp.code === 1000) {
          this.$message({
            message: 'Send NOA Acknowledgement Reminder Successful!',
            type: 'success'
          })
        }
      })
    },
    onUpdateAcknowledgeNoa (val) {
      updateAcknowledgeNoa(this.$attrs.formId).then(resp => {
        if (resp.code === 1000) {
          this.$message({
            message: 'Checked Successful!',
            type: 'success'
          })
          this.$emit('populateFormData')
        } else {
          this.$message({
            message: resp.message,
            type: 'error'
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../index.scss";
</style>
